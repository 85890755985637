<template>
    <vx-card :title="'Detail of ' + (this.po ? 'Purchase Order' : 'Supplier') + ' Return'">
        <div class="vx-row mb-6">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier return type</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="selectedReturnType ? selectedReturnType.name : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>PO Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="purchase_order ? purchase_order.code : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="supplier ? supplier.code : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Name</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="supplier ? supplier.name : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Supplier Address</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="supplier ? supplier.address : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>GR Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select
                            multiple
                            :filterable="true"
                            :disabled="true"
                            v-model="gr_number"
                            label="code"
                            :clearable="false"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2" v-if="selectedReturnType.value">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Tax Rate</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            :value="tax ? tax.name : ''"
                            readonly
                            class="w-full"
                        />
                    </div>
                </div>
                <vs-divider style="width: 60%; margin-left: 0%">
                    List Attachment
                </vs-divider>
                <div class="mb-6 vx-row pt-2" style="margin-left: 0%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td>
                                    <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                </td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                        <vs-button 
                                            type="line" 
                                            icon-pack="feather" 
                                            icon="icon-download" 
                                            :disabled="!tr.PathFile"
                                            @click.stop="download(tr)"
                                        />
                                    </vx-tooltip>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
                <vue-easy-lightbox
                    :visible="visible"
                    :imgs="images"
                    :index="index"
                    @hide="handleHide"
                />
                <br>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Supplier Return Data</h4>
                    <hr>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>SR Number</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="code"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>GRR Number</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="purchase_return_outbound ? purchase_return_outbound.code : ''"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Created At</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="created_at"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Created By</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="created_by"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Description</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                v-model="description"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Warehouse</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-input
                                type="text"
                                :value="warehouse_label ? warehouse_label : ''"
                                readonly
                                class="w-full"
                            />
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Return Date</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <datepicker 
                                format="yyyy-MM-dd" 
                                name="date" 
                                v-model="date"
                                class="w-full" 
                                :disabled="true">
                            </datepicker>
                        </div>
                    </div>
                    <div class="mb-6 vx-row pt-2">
                        <div class="flex items-center w-full vx-col sm:w-1/3">
                            <span>Note</span>
                        </div>
                        <div class="w-full vx-col sm:w-2/3">
                            <vs-textarea v-model="note" readonly width="100%" />
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <!-- ... -->
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>SR Data</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <table class="m-3 vs-table vs-table--tbody-table" width="100%">
                <thead class="vs-table--thead" v-if="!selectedReturnType.value">
                    <tr>
                        <th width="25%">SKU Description</th>
                        <th width="7%">Tax</th>
                        <th width="5%">Returned Qty</th>
                        <th width="7%">HU</th>
                        <th width="10%">Price</th>
                        <th width="18%">Batch / Exp. Date</th>
                        <th width="14%">Warehouse Area</th>
                        <th width="14%">Reason</th>
                    </tr>
                </thead>

                <thead class="vs-table--thead" v-if="selectedReturnType.value">
                    <tr>
                        <th width="28%">SKU Description</th>
                        <th width="5%">Qty</th>
                        <th width="5%">Returned Qty</th>
                        <th width="7%">HU</th>
                        <th width="10%">Price</th>
                        <th width="15%">Batch / Exp. Date</th>
                        <th width="15%">Warehouse Area</th>
                        <th width="15%">Reason</th>
                    </tr>
                </thead>

                <tbody v-if="!selectedReturnType.value">
                    <template v-for="(skuLine, index) in skuLinesNoPo">
                        <tr style="padding-bottom: 5px" :key="index">
                            <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                <vs-textarea
                                    type="text"
                                    :value="skuLine.label"
                                    class="w-full"
                                    readonly
                                    disabled
                                    style="word-wrap: break-word;"
                                ></vs-textarea>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                <vs-input
                                    type="text"
                                    :value="skuLine.skuTax ? skuLine.skuTax.name : '-'"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>

                            <!-- first putaway lines -->
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input
                                    type="number"
                                    v-model="skuLine.putaway_lines[0].return_qty"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].selected_unit"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].price"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-textarea
                                    type="text"
                                    :value="skuLine.putaway_lines[0].batch_external ? skuLine.putaway_lines[0].batch_external + ' / ' + skuLine.putaway_lines[0].exp_date : ''"
                                    readonly
                                    disabled
                                    class="w-full"
                                    style="word-wrap: break-word;"
                                ></vs-textarea>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-textarea
                                    type="text"
                                    :value="skuLine.putaway_lines[0].warehouse_area ? skuLine.putaway_lines[0].warehouse_area.name : ''"
                                    readonly
                                    disabled
                                    class="w-full"
                                    style="word-wrap: break-word;"
                                ></vs-textarea>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].reason ? skuLine.putaway_lines[0].reason.reason : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                        </tr>

                        <!-- added putaway lines -->
                        <template v-for="(putaways, idx) in skuLinesNoPo[index].putaway_lines">
                            <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="number"
                                        v-model="putaways.return_qty"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.selected_unit"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.price"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-textarea
                                        type="text"
                                        :value="putaways.batch_external ? putaways.batch_external + ' / ' + putaways.exp_date : ''"
                                        readonly
                                        disabled
                                        class="w-full"
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-textarea
                                        type="text"
                                        :value="putaways.warehouse_area ? putaways.warehouse_area.name : ''"
                                        readonly
                                        disabled
                                        class="w-full"
                                        style="word-wrap: break-word;"
                                    ></vs-textarea>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.reason ? putaways.reason.reason : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>

                <tbody v-if="selectedReturnType.value">
                    <template v-for="(skuLine, index) in skuLines">
                        <tr style="padding-bottom: 5px" :key="index">
                            <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                <vs-textarea
                                    type="text"
                                    :value="skuLine.label"
                                    class="w-full"
                                    readonly
                                    disabled
                                    style="word-wrap: break-word;"
                                ></vs-textarea>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                <vs-input
                                    type="number"
                                    :value="skuLine.qty"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>

                            <!-- first putaway lines -->
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="number"
                                    :value="skuLine.putaway_lines[0].return_qty"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].selected_unit"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].price"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].batch ? skuLine.putaway_lines[0].batch.label : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].warehouse_area ? skuLine.putaway_lines[0].warehouse_area.name : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].reason ? skuLine.putaway_lines[0].reason.reason : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                        </tr>

                        <!-- added putaway lines -->
                        <template v-for="(putaways, idx) in skuLines[index].putaway_lines">
                            <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="number"
                                        :value="putaways.return_qty"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.selected_unit"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.price"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.batch ? putaways.batch.label : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.warehouse_area ? putaways.warehouse_area.name : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.reason ? putaways.reason.reason : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        <br>
        <div class="flex md:flex-row" v-if="grrLines.length">
            <div class="w-full">
                <h4>GRR Data</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="mb-6 vx-row" v-if="grrLines.length">
            <table class="m-3 vs-table vs-table--tbody-table" width="100%">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="20%">SKU Description</th>
                        <th width="5%">Returned Qty</th>
                        <th width="10%">HU</th>
                        <th width="10%">Price</th>
                        <th width="10%">Batch</th>
                        <th width="15%">Warehouse Area</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(skuLine, index) in grrLines">
                        <tr style="padding-bottom: 5px" :key="index">
                            <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLine.putaway_lines.length">
                                <vs-input
                                    type="text"
                                    :value="skuLine.label"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>

                            <!-- first putaway lines -->
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="number"
                                    :value="skuLine.putaway_lines[0].return_qty"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].unit"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].price"
                                    class="w-full"
                                    readonly
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].batch ? skuLine.putaway_lines[0].batch.label : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    :value="skuLine.putaway_lines[0].warehouse_area ? skuLine.putaway_lines[0].warehouse_area.name : ''"
                                    readonly
                                    class="w-full"
                                ></vs-input>
                            </td>
                        </tr>

                        <!-- added putaway lines -->
                        <template v-for="(putaways, idx) in grrLines[index].putaway_lines">
                            <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="number"
                                        :value="putaways.return_qty"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.unit"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.price"
                                        class="w-full"
                                        readonly
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.batch ? putaways.batch.label : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="text"
                                        :value="putaways.warehouse_area ? putaways.warehouse_area.name : ''"
                                        readonly
                                        class="w-full"
                                    ></vs-input>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button 
                        class="ml-4 mt-2" 
                        color="danger" 
                        icon-pack="feather" 
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import moment from "moment";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    components: {
        Date,
        vSelect,
        Datepicker,
        VueEasyLightbox,
    },
    mounted() {
        this.selectedReturnType = this.returnType[0]
        this.id = this.$route.params.id;
        this.po = this.$route.params.po;
        if (this.id) {
            this.getData()
            if (this.po == 0) {
                this.selectedReturnType = this.returnType[1]
            }
        }
    },
    data() {
        return {
            id: 0,
            po: 0,
            code: null,
            status: null,
            created_at: null,
            created_by: null,
            description: "",
            warehouse: null,
            warehouse_label: "",
            date: moment().toDate(),
            note: "",
            purchase_order_id: 0,
            purchase_order: null,
            supplier_id: 0,
            supplier: null,
            purchase_return_outbound: null,
            gr_number: [],
            tax: null,
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            selectedReturnType: {
                value: false,
                name: "",
            },
            skuLines: [
                {
                    purchase_receive_id: 0,
                    purchase_receive_line_id: 0,
                    purchase_order_line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    qty: 0,
                    option_unit: [],
                    putaway_lines: [
                        {
                            return_line_id: 0,
                            putaway_id: 0,
                            selected_unit: null,
                            price: null,
                            return_qty: 0,
                            batch: null,
                            warehouse_area: null,
                            reason: null,
                        }
                    ]
                }
            ],
            skuLinesNoPo: [
                {
                    supplier_return_id: 0,
                    optionSKU: [],
                    sku_code: null,
                    item_name: null,
                    label: null,
                    option_unit: [],
                    skuTax: null,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            selected_unit: null,
                            price: null,
                            rawPrice: 0,
                            return_qty: 0,
                            batch: null,
                            warehouse_area: null,
                            reason: null,
                        }
                    ]
                }
            ],
            grrLines: [
                {
                    sku_code: null,
                    item_name: null,
                    label: null,
                    putaway_lines: [
                        {
                            outbound_line_id: 0,
                            return_qty: 0,
                            unit: null,
                            price: null,
                            rawPrice: 0,
                            batch: null,
                            warehouse_area: null,
                        }
                    ]
                }
            ],

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    methods: {
        getData() {
            if (this.po == 0) {
                this.$vs.loading();
                this.$http.get(this.$store.state.purchase.supplierReturn.baseUrlPath + "/edit-detail/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.id = resp.data.supplier_return.id
                        this.code = resp.data.supplier_return.code
                        this.status = resp.data.status
                        this.created_at = resp.data.created_at
                        this.created_by = resp.data.personal.name
                        this.description = resp.data.supplier_return.description
                        this.warehouse = resp.data.warehouse
                        this.warehouse_label = this.warehouse.code+" - "+this.warehouse.name
                        this.date = this.formatDate(resp.data.supplier_return.date)
                        this.note = resp.data.supplier_return.note
                        this.supplier = resp.data.supplier
                        this.supplier_id = resp.data.supplier.id
                        this.purchase_return_outbound = resp.data.purchase_return_outbound
                        this.tax = resp.data.tax
                        this.skuLinesNoPo = resp.data.sku_lines
                        this.grrLines = resp.data.grr_lines

                        for (let i = 0; i < this.skuLinesNoPo.length; i++) {
                            for (let j = 0; j < this.skuLinesNoPo[i].putaway_lines.length; j++) {
                                this.updateAmount(i, j, this.skuLinesNoPo[i].putaway_lines[j].rawPrice)
                            }
                        }

                        if (this.grrLines.length > 0) {
                            for (let i = 0; i < this.grrLines.length; i++) {
                                for (let j = 0; j < this.grrLines[i].putaway_lines.length; j++) {
                                    this.updateAmountGrr(i, j, this.grrLines[i].putaway_lines[j].rawPrice)
                                }
                            }
                        }

                        if (resp.data.attachment) {
                            resp.data.attachment.forEach((val) => {
                                this.fileAttachment.push({
                                    id: val.id,
                                    NameFile: val.file_name,
                                    PathFile: val.file_url,
                                });
                                this.images.push(val.file_url);
                            });
                        }
                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$vs.loading.close();
                });
            } else {
                this.$vs.loading();
                this.$http.get("api/wms/v1/wms/purchase/po-return/edit-detail/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.id = resp.data.purchase_return.id
                        this.code = resp.data.purchase_return.code
                        this.status = resp.data.status
                        this.created_at = resp.data.created_at
                        this.created_by = resp.data.personal.name
                        this.description = resp.data.purchase_return.description
                        this.warehouse = resp.data.warehouse
                        this.warehouse_label = this.warehouse.code+" - "+this.warehouse.name
                        this.date = this.formatDate(resp.data.purchase_return.date)
                        this.note = resp.data.purchase_return.note
                        this.purchase_order = resp.data.purchase_order
                        this.purchase_order_id = resp.data.purchase_order.id
                        this.supplier = resp.data.supplier
                        this.supplier_id = resp.data.supplier.id
                        this.purchase_return_outbound = resp.data.purchase_return_outbound
                        this.gr_number = resp.data.gr_data
                        this.tax = resp.data.tax
                        this.skuLines = resp.data.sku_lines
                        this.grrLines = resp.data.grr_lines

                        for (let i = 0; i < this.skuLines.length; i++) {
                            for (let j = 0; j < this.skuLines[i].putaway_lines.length; j++) {
                                let skuCode = this.skuLines[i].sku_code
                                let itemUnit = this.skuLines[i].putaway_lines[j].selected_unit
                                this.handleGetPrice(this.supplier_id, this.warehouse.id, skuCode, itemUnit, i, j)
                            }
                        }

                        if (this.grrLines.length > 0) {
                            for (let i = 0; i < this.grrLines.length; i++) {
                                for (let j = 0; j < this.grrLines[i].putaway_lines.length; j++) {
                                    let skuCode = this.grrLines[i].sku_code
                                    let itemUnit = this.grrLines[i].putaway_lines[j].unit
                                    this.handleGetPriceGrr(this.supplier_id, this.warehouse.id, skuCode, itemUnit, i, j)
                                }
                            }
                        }

                        if (resp.data.attachment) {
                            resp.data.attachment.forEach((val) => {
                                this.fileAttachment.push({
                                    id: val.id,
                                    NameFile: val.file_name,
                                    PathFile: val.file_url,
                                });
                                this.images.push(val.file_url);
                            });
                        }
                        this.$vs.loading.close();
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$vs.loading.close();
                });
            }
        },
        handleGetPrice(supplierID, warehouseID, skuCode, unit, index, idx) {
            let param = {
                id: this.id,
                supplier_id: supplierID,
                warehouse_id: warehouseID,
                sku_code: skuCode,
                unit: unit,
            }

            this.$http
                .post(this.$store.state.purchase.supplierReturn.baseUrlPath + "/get-item-price", param)
                .then((resp) => {
                    if (this.selectedReturnType.value) {
                        if (resp.code == 200) {
                            this.updateAmount(index, idx, resp.data.item_price)
                        } else {
                            this.skuLines[index].putaway_lines[idx].price = 0
                        }
                    } else {
                        if (resp.code == 200) {
                            this.updateAmount(index, idx, resp.data.item_price)
                        } else {
                            this.skuLinesNoPo[index].putaway_lines[idx].price = 0
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        handleGetPriceGrr(supplierID, warehouseID, skuCode, unit, index, idx) {
            let param = {
                id: this.id,
                supplier_id: supplierID,
                warehouse_id: warehouseID,
                sku_code: skuCode,
                unit: unit,
            }

            this.$http
                .post(this.$store.state.purchase.supplierReturn.baseUrlPath + "/get-item-price", param)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.updateAmountGrr(index, idx, resp.data.item_price)
                    } else {
                        this.grrLines[index].putaway_lines[idx].price = 0
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        updateAmount(index, idx, val) {
            if (this.selectedReturnType.value) {
                let cleanValue = val.toString().replace(/\D/g, '');
                let formattedValue = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2
                }).format(cleanValue);

                this.skuLines[index].putaway_lines[idx].price = formattedValue;
            } else {
                let cleanValue = val.toString().replace(/\D/g, '');
                let formattedValue = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 2
                }).format(cleanValue);

                this.skuLinesNoPo[index].putaway_lines[idx].price = formattedValue;
            }
        },
        updateAmountGrr(index, idx, val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            let formattedValue = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2
            }).format(cleanValue);

            this.grrLines[index].putaway_lines[idx].price = formattedValue;
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: "purchase.po-return.approval",
            });
            this.$vs.loading.close();
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>